import Image from "next/image";

import splitImg from "../../public/images/split/cyber-2.png";
import Link from "next/link";

const Split = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-4 col-12">
                  <div className="thumbnail">
                    <Image
                      className="radius"
                      src={splitImg}
                      alt="split Images"
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-xl-8 col-12">
                  <div className="split-inner">
                    <h4
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      Agriculture with Cyber Intelligence
                    </h4>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      An innovative AI-powered mobile application designed to revolutionize crop management for farmers.
                    </p>
                    <ul
                      className="split-list"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="350"
                    >
                      <li>- Accurately diagnose diseases affecting major crops using advanced computer vision and machine learning models.</li>
                      <li>- Leverage AI-driven insights for precise crop management, optimizing inputs, and maximizing yields.</li>
                      <li>- Access an AI-powered chat interface to ask agriculture-related questions and receive expert guidance on-demand.</li>
                      <li>- Get targeted recommendations for effective disease management, pest control, and cultural practices.</li>
                      <li>- Utilize AI models to forecast crop yields based on various factors, enabling better planning and decision-making.</li>
                      <li>- Promote environmentally-friendly farming methods by minimizing fertilizer usage and optimizing resource utilization.</li>
                    </ul>
                    {/* <div
                      className="view-more-button mt--35"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="400"
                    >
                      <Link className="btn-default" href="/contact">
                        Contact With Us
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Split;
